@import url('https://fonts.googleapis.com/css2?family=Prompt:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

html, body {
  background-color: #FFF;
  font-family: 'Prompt', sans-serif;
  min-height: 100%;
  overflow-x: hidden;
}

*::before,
*::after,
* {
  margin: 0;
  padding:0;
  box-sizing: border-box;
}

ul {
  list-style: none;
}

button, a {
  color: #000;
  text-decoration: none;
  outline: none;
}

#root {
  height: 100%;
  position: relative;
}

nav {
  display: flex;
  justify-content: space-between;
  padding: 15px 70px;
  background-color:#FFF;
  position: relative;
  width: 100%;
  z-index: 9999;
  filter: drop-shadow(0px 4px 98px #00000014);
}

nav.nav__fixed {
  position: fixed;
}

nav .navbar__contianer {
  display: flex;
  gap: 32px;
  align-items: center;
}

.sideNav {
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: #dcdcdc45;
  left: 0px;
  z-index: 9999;
  top: 0;
  display: none;
}

.sideNav.active {
  display: block;
}

.sideNav .navbar__contianer {
  width: 200px;
  height: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 25px;
  gap: 35px;
  transform: translateX(0px);
}

.sideNav.active .navbar__contianer {
  animation: showHam 2s ease;
}

.sideNav .navbar__contianer li {
  font-size: 24px;
}

.sideNav .navbar__contianer li a {
  position: relative;
}

.sideNav button {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 20px;
  right: 20px;
  border: none;
  background-color: transparent;
  font-size: 24px;
  color: #F24D2B;
}

nav .navbar__contianer li {
  height: 100%;
}

nav .navbar__contianer li.hamMenu {
  display: none;
  justify-content: center;
  align-items: center;
}

nav .navbar__contianer li.hamMenu button {
  border: none;
  background-color: transparent;
  font-size: 24px;
}

nav .navbar__contianer li.dropdown svg {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

nav .navbar__contianer li.dropdown:hover svg {
  transform: rotate(180deg);
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
}

nav .navbar__contianer li a {
  font-size: 16px;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  position: relative;
}


nav .logo__img {
  position:relative
}

nav .navbar__contianer li a::before,
nav .logo__img::before,
.sideNav .navbar__contianer li a::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 5px;
  background-color: transparent;
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  bottom: -15px;
}

nav .logo__img::before {
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
}

nav .navbar__contianer li a:hover::before,
nav .navbar__contianer li.active a::before,
nav .logo__img.active::before,
.sideNav .navbar__contianer li.active a::before {
  background-color: var(--highlight-color);
}


nav .logo__img img {
  width: 150px;
}

nav .navbar__contianer li.dropdown div {
  -webkit-transition: .3s all ease;
  -o-transition: .3s all ease;
  transition: .3s all ease;
  padding: 10px 10px;
  width: 180px;
  position: absolute;
  display: none;
  background-color: #FFF;
}


nav .navbar__contianer li.dropdown div::before {
  content: '';
  position: absolute;
  top:0;
  width: 60%;
  height: 5px;
  background-color: var(--highlight-color);
}


nav .navbar__contianer li.dropdown:hover div {
  display: block;
}

/* start preloader */
.preloader {
  position: fixed;
  z-index: 99999;
  top: 0;
  background-color: #FFF;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}

.preloader.active {
  display: flex;
}

.preloader .img__shadow__cont {
  position: relative;
}

.preloader .img__shadow__cont .img {
  width: 100px;
  height: 100px;
  background-color: #fff;
  border-radius: 50%;
  position: relative;
  z-index: 9;
}

.preloader .img__shadow__cont .img > img {
  width: 100%;
  height: 100%;
}

.preloader .img__shadow__cont .img__shadow {
  width: 100px;
  height: 100px;
  background-color: #0000002b;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  animation: scalePreload 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.preloader.active .img__shadow__cont .img__shadow {
  animation: scalePreload 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes scalePreload {
  0%, 100% {
    transform: scale(1.1);
  }
  25%, 75% {
    transform: scale(1.15);
  }
  50% {
    transform: scale(1.35);
  }
}
/* end preloader */

/* Start Footer */
footer {
  width: 100%;
  padding: 20px 70px;
  padding-top: 50px;
  background-color: var(--dark-shade);
}

footer .logo__img img {
  width: 180px;
}

footer > div {
  display: flex;
  justify-content: space-between;
  gap: 50px;
}

footer .contact__info {
  display: flex;
  justify-content: flex-end;
  gap:50px;
}

footer .contact__info > div {
  font-weight: 400;
  font-size: 14px;
  color: #FFF;
  max-width: 33.33%;
}

footer .contact__info > div a {
  font-weight: 400;
  font-size: 14px;
  color: #FFF;
}

footer .contact__info .social_icons {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap:10px;
}

footer .contact__info .social_icons > span {
  display: inline-block;
  width: 26px;
  height: 26px;
}

footer .contact__info .social_icons > span:first-of-type {
  width: 28px;
  height: 28px;
}

footer .contact__info .social_icons > span > svg {
  width: 100%;
  height: 100%;
}

footer p {
  margin-top: 10px;
  font-size: 12px;
  color: #FFF;
  text-align: center;
  font-weight: 400;
}
/* End Footer */

.mainBody {
  height: 100%;
}

/* Start Landing Page */

.mainBody.landing .hero {
  height: calc(100vh - 79px);
  position: relative;
}


.skew__rectangle_container {
  position: absolute;
  height: 45px;
  width: 100%;
  bottom: 0;
  display: flex;
}

.skew__rectangle_container > div {
  width: 50%;
  height: 100%;
  transform: skew(48deg) translateX(-25px);
  background-color: #FFF;
}

.skew__rectangle_container > div:last-of-type {
  transform: skew(-48deg) translateX(25px);
}


.mainBody.landing .hero .carousel-root,
.mainBody.landing .hero .carousel-root .carousel.carousel-slider {
  height: 100%;
}

.mainBody.landing .hero .carousel__item {
  position: relative;
}

.mainBody.landing .hero .carousel__item > div {
  position: absolute;
  width: 50%;
  padding-block: 30px; 
  padding-inline: 20px;
  top: 50%;
  right: 0px;
  transform: translate(30px, -100%);
  padding-right: 50px;
}
.mainBody.landing .hero .carousel__item > div > .flag {
  position: absolute;
  background-color: #003063;
  width: 100%;
  height: 100%;
  transform: skew(30deg);
  opacity: 0.89;
  top: 0px;
}

.mainBody.landing .hero .carousel__item .heroText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mainBody.landing .hero .carousel__item .heroText h4,
.mainBody.landing .hero .carousel__item .heroText p {
  position: relative;
  color: #FFF;
}

.mainBody.landing .hero .carousel__item .heroText p {
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.3px;
  width: 80%;
  text-align: start;
}

.mainBody.landing .hero .carousel__item .heroText h4 {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.mainBody.landing .about {
  padding: 40px 70px;
  display: flex;
  gap: 20px;
  padding-bottom: 70px;
}

.mainBody.landing .about > div {
  width: 60%;
}

.mainBody.landing .about > div:last-of-type {
  width: 40%;
}

.mainBody.landing .about h4,
.mainBody.landing .vision h4,
.mainBody.landing .mission h4,
.mainBody.landing .service > .service__description h4,
.mainBody.landing .policy > .policy__description h4,
.mainBody.landing .info > .info__description h4 {
  font-weight: 600;
  font-size: 32px;
}

.mainBody.landing .about p,
.mainBody.landing .vision p,
.mainBody.landing .mission p,
.mainBody.landing .service > .service__description p,
.mainBody.landing .policy > .policy__description p,
.mainBody.landing .info > .info__description p {
  font-weight: 400;
  font-size: 14px;
  margin-top: 20px;
}

.mainBody.landing .about .about__description {
  position: relative;
  z-index: 99;
}

.mainBody.landing .about .barrell {
  position: relative;
}

.mainBody.landing .about .barrell::before {
  content: '';
  position: absolute;
  width: calc(100% + 142px);
  height: calc(100% + 155px);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,238,238,1) 35%, rgba(255,238,238,1) 100%);
  top: -85px;
  right: -70px;
}

.mainBody.landing .about .barrell > div {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainBody.landing .about .barrell .barrel__circle {
  background-color: #003063;
  width: 250px;
  height: 250px;
  border-radius: 100%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translateX(-50%);
}

.mainBody.landing .about .barrell .barrel__mdcircle {
  background-color: #F99A00;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-30%);
}

.mainBody.landing .about .barrell .barrel__smcircle {
  background-color: #F99A00;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(0px, -60%);
}

.mainBody.landing .about .barrell .barrel {
  position: absolute;
  width: 450px;
  height: 500px;
  bottom: -80%;
}

.mainBody.landing .about .barrell .barrel > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.mainBody.landing .vision,
.mainBody.landing .mission {
  padding: 50px 70px;
  display: flex;
  gap: 20px;
  background-color: #1E1E1E;
}

.mainBody.landing .vision > div {
  width: 40%;
}

.mainBody.landing .vision > div:last-of-type {
  width: 60%;
}

.mainBody.landing .vision p,
.mainBody.landing .vision h4 {
  color: #FFF;
}

.mainBody.landing .vision .binocular {
  position: relative;
}

.mainBody.landing .vision .binocular > div {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.mainBody.landing .vision .binocular .binoculars__circle {
  background-color: #0C1832;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  position: absolute;
  left: 10px;
}

.mainBody.landing .vision .binocular .binoculars {
  position: absolute;
  width: 250px;
  height: 250px;
}

.mainBody.landing .vision .binocular .binoculars > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.mainBody.landing .vision .binocular .binoculars__smcircle {
  background-color: #F32B02;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: -10px;
}

.mainBody.landing .mission {
  position: relative;
  background-color: #93DBFF;
  padding: 0px;
  gap: 0px;
}

.mainBody.landing .mission > div {
  width: 50%;
}

.mainBody.landing .mission .mission__description {
  background-color: #93DBFF;
  padding: 120px 70px;
}

.mainBody.landing .mission .mission__img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.mainBody.landing .mission .skew__rectangle_container {
  width: 100%;
}

.mainBody.landing .service,
.mainBody.landing .policy,
.mainBody.landing .info {
  padding: 50px 70px;
}

.mainBody.landing .service > .service__description,
.mainBody.landing .policy > .policy__description,
.mainBody.landing .info > .info__description {
  text-align: center;
}

.mainBody.landing .service > .service__description > p,
.mainBody.landing .policy > .policy__description > p,
.mainBody.landing .info > .info__description > p {
  padding-inline: 150px;
}

.mainBody.landing .service .slider_carousel {
  position: relative;
  margin-block: 40px;
}

.mainBody.landing .service .slider_carousel > .left-flag {
  position: absolute;
  height: calc(100% + 40px);
  width: 70px;
  z-index: 99;
  transform: rotate(180deg);
  left: -70px;
  background: #FFF;
  top: -20px;
}

.mainBody.landing .service .slider_carousel > .right-flag {
  position: absolute;
  height: calc(100% + 40px);
  width: 70px;
  z-index: 99;
  right: -70px;
  background: #FFF;
  top: -20px;
}

.mainBody.landing .service .slider_carousel .slider_carousel__item,
.mainBody.landing .service .slider_carousel .slider_carousel__item > div {
  width: 100%;
  height: 100%;
  position: relative;
}

.mainBody.landing .service .react-multi-carousel-list {
  overflow: unset!important;
}

.mainBody.landing .service .slider_carousel .slider_carousel__item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.mainBody.landing .service .slider_carousel .slider_carousel__item .overlay {
  position: absolute;
  background-color: rgba(0, 19, 40, 0.7);
  width: 100%;
  height: 100%;
  bottom: 0;
  color: #fff;
  padding: 15px 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  animation: closeheight 1s;
  animation-fill-mode: forwards;
}

.mainBody.landing .service .slider_carousel .slider_carousel__item .overlay .description {
  width: 100%;
  height: 0px;
  visibility: hidden;
  animation: hideDesc 1s;
  animation-fill-mode: forwards;
}

.mainBody.landing .service .slider_carousel .react-multi-carousel-item {
  transform: scale(1);
  transition: transform 1s ease;
}

.mainBody.landing .service .slider_carousel .react-multi-carousel-item:hover {
  transform: scale(1.07);
  z-index: 9999;
  transition: transform 1s ease;
}

.mainBody.landing .service .slider_carousel .react-multi-carousel-item:hover .slider_carousel__item .overlay {
  height: auto;
  animation: openheight 1s;
  animation-fill-mode: forwards;
}

.mainBody.landing .service .slider_carousel .react-multi-carousel-item:hover .slider_carousel__item .overlay .description {
  height: 100px;
  visibility: visible;
  animation: showDesc 1s;
  animation-fill-mode: forwards;
}

.mainBody.landing .service .services_footer {
  margin-block: 20px;
  margin-top: 40px;
}

.mainBody.landing .service .services_footer p {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 10px;
}

.mainBody.landing .service .services_footer > div,
.mainBody.landing .info .info__description > div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.mainBody.landing .service .services_footer a,
.mainBody.landing .info a {
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  color: #FFF;
  padding: 8px 20px;
  background-color: #F24D2B;
  border-radius: 40px;
}

.mainBody.landing .service .services_footer a:hover {
  background-color: #f46e53;
}

.mainBody.landing .policy {
  background-color: #1E1E1E;
}

.mainBody.landing .policy > .policy__description {
  color: #FFF;
}

.mainBody.landing .policy > .policy_item__container {
  display: flex;
  padding: 50px 40px;
  justify-content: center;
  align-items: center;
}

.mainBody.landing .policy > .policy_item__container > .policy_item {
  width: 25%;
  text-align: center;
  color: #FFF;
}

.mainBody.landing .info a {
  padding: 8px 24px;
  font-size: 14px;
  background-color: #0093DE;
}

.mainBody.landing .info a:hover {
  background-color: #5dc6fa;
}

@keyframes showDesc {
  from {
    height: 0px;
    visibility: hidden;
  }
  to {
    height: 100px;
    visibility: visible;
  }
}

@keyframes hideDesc {
  from {
    height: 100px;
    visibility: visible;
  }
  to {
    height: 0px;
    visibility: hidden;
  }
}

@keyframes closeheight {
  from {
    height: auto;
  }
  to {
    height: 100%;
  }
}

@keyframes openheight {
  from {
    height: 100%;
  }
  to {
    height: auto;
  }
}

@keyframes showHam {
  from {
    transform: translateX(-200px);
  }
  to {
    transform: translateX(0px);
  }
}
/* End Landing Page */

/* Start Portfolio Page */

.mainBody.portfolio {
  min-height: calc(100vh - 79px);
}

.mainBody.portfolio .banner {
  height: 180px;
  position: relative;
  background-image: url('./assets/img/banner.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainBody.portfolio .banner > div {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 48, 99, 0.86);
}

.mainBody.portfolio .banner > h3 {
  color: #FFF;
  position: relative;
  font-size: 38px;
  font-weight: 700;
}

.mainBody.portfolio .portfolio__container {
  position: relative;
  padding: 50px 70px;
  display: flex;
  flex-wrap: wrap;
  /* gap: 30px; */
}

.mainBody.portfolio .portfolio__container > .portfolio__item {
  width: 33.33%;
  padding-left: 20px;
  padding-right: 20px;
}

.mainBody.portfolio .portfolio__container > .portfolio__item > .port__img > img {
  width: 100%;
}

.mainBody.portfolio .portfolio__container > .portfolio__item h4 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 16px;
}

.mainBody.portfolio .portfolio__container > .portfolio__item .port__divider {
  width: 100%;
  background-color: #0093DE;
  height: 2px;
  margin-bottom: 10px;
}

.mainBody.portfolio .portfolio__container > .portfolio__item h6 {
  font-weight: 600;
  font-size: 16px;
}

.mainBody.portfolio .portfolio__container > .portfolio__item p {
  margin-top: 5px;
  font-weight: 400;
  font-size: 13px;
}
/* End Portfolio Page */

/* Start Contact Page */
.mainBody.contact {
  height: calc(100vh - 79px);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F6F6F6;
}
.mainBody.contact > div {
  width: 50%;
}

.mainBody.contact .contact__us__img {
  background-image: url('./assets/img/hero1.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  background-position: center;
}

.mainBody.contact .contactFormContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainBody.contact .contactFormContainer .contactHeader {
  text-align: center;
}

.mainBody.contact .contactFormContainer .contactHeader > h2 {
  font-weight: 700;
  font-size: 38px;
  letter-spacing: -0.5px;
}

.mainBody.contact .contactFormContainer .contactHeader > p {
  font-weight: 400;
  font-size: 13px;
  color: #1E1E1E;
}

.mainBody.contact .contactFormContainer .contactForm {
  margin-block: 30px;
  padding-inline: 100px;
  width: 100%;
}

.mainBody.contact .contactFormContainer .contactForm .mainForm {
  display: flex;
  flex-wrap: wrap;
}

.mainBody.contact .contactFormContainer .contactForm .mainForm > div{
  padding-inline: 10px;
  margin-block: 12px;
}

.mainBody.contact .contactFormContainer .contactForm .mainForm > div.w-100 {
  width: 100%;
}

.mainBody.contact .contactFormContainer .contactForm .mainForm > div.w-50 {
  width: 50%;
}

.mainBody.contact .contactFormContainer .contactForm input,
.mainBody.contact .contactFormContainer .contactForm textarea {
  background-color: #FFF;
  border: 1px solid #CDCDCD;
  height: 40px;
  width: 100%;
  border-radius: 20px;
  padding-inline: 20px;
  outline: none;
  color: #1E1E1E;
}

.mainBody.contact .contactFormContainer .contactForm textarea {
  height: 120px;
  border-radius: 15px;
  padding-block: 10px;
  width: 100%;
  resize: none;
}

.mainBody.contact .contactFormContainer .contactForm input::placeholder,
.mainBody.contact .contactFormContainer .contactForm textarea::placeholder {
  color: #979797;
}

.mainBody.contact .contactFormContainer .contactForm input::-moz-placeholder,
.mainBody.contact .contactFormContainer .contactForm textarea::-moz-placeholder {
  color: #979797;
}

.mainBody.contact .contactFormContainer .contactForm .submitBtn {
  text-align: center;
  margin-block: 5px;
}

.mainBody.contact .contactFormContainer .contactForm .submitBtn > button {
  padding: 10px 24px;
  font-size: 14px;
  background-color: #F24D2B;
  color:#FFF;
  border: none;
  border-radius: 24px;
  cursor: pointer;
}

.mainBody.contact .contactFormContainer .contactForm .submitBtn > button:hover {
  background-color: #fa7d65;
}
/* End Contact Page */
@media screen and (min-width: 1600px) {
  .mainBody.landing .hero {
    height: 100%;
  }

  .skew__rectangle_container {
    bottom: -1px;
  }

  .mainBody.landing .about .barrell::before {
    height: calc(100% + 154px);
    top: -84px;
  }

  .mainBody.landing .about .barrell .barrel {
      width: 400px;
      height: 450px;
      bottom: -90%;
  }

  .mainBody.landing .about .barrell .barrel__smcircle {
    bottom: -56%;
  }

  .mainBody.landing .about .barrell .barrel__circle {
    width: 230px;
    height: 230px;
    left: 48%;
    bottom: -49px;
  }
}
@media screen and (max-width: 1024px) {
  .mainBody.landing .hero {
    height: 485px;
  }

  .mainBody.landing .hero .carousel__item {
    height: 485px;
  }

  .carousel .slide img {
    height: 100%;
    object-fit: cover;
  }

  .skew__rectangle_container {
    bottom: -2px;
  }

  .mainBody.landing .hero .carousel__item > div {
    width: 70%;
    top: 60%;
  }

  .mainBody.landing .about .barrell::before {
    top: -80px;
    right: -120px;
    height: calc(100% + 150px);
  }

  .mainBody.landing .about .barrell .barrel__circle {
    bottom: -60px;
  }

  .mainBody.landing .about .barrell .barrel {
    width: 450px;
    height: 500px;
    bottom: -70%;
  }

  .mainBody.landing .about .barrell .barrel__mdcircle {
    top:50px;
  }

  .mainBody.landing .about .barrell .barrel__smcircle {
    bottom: -40%;
  }

  footer > div {
    flex-direction: column;
    justify-content: center;
    gap: 20px;
  }

  footer > div .logo__img {
    text-align: center
  }

  .mainBody.portfolio .portfolio__container {
    row-gap: 30px;
  }

  .mainBody.portfolio .portfolio__container > .portfolio__item {
    width: 50%;
  }

  .mainBody.contact .contact__us__img {
    display: none;
  }

  .mainBody.contact .contactFormContainer .contactForm {
    padding-inline: 0px;
  }

  footer .contact__info {
    justify-content: center;
  }
}

@media screen and (max-width: 768px) {
  .mainBody.landing .hero {
    height: 365px;
  }

  .mainBody.landing .hero .carousel__item {
    height: 365px;
  }

  .carousel .slide img {
    height: 100%;
    object-fit: cover;
  }

  .mainBody.landing .hero .carousel__item > div {
    width: 70%;
    top: 60%;
  }

  .mainBody.landing .about .barrell::before {
    top: -85px;
    right: -120px;
  }

  .mainBody.landing .about .barrell .barrel__circle {
    width: 250px;
    height: 250px;
  }

  .mainBody.landing .about .barrell .barrel__mdcircle {
    top: 120px;
  }


  .mainBody.landing .about .barrell .barrel__smcircle {
    bottom: -35%;
  }

  .mainBody.landing .about .barrell .barrel {
    width: 450px;
    height: 450px;
    bottom: -50%;
  }

  footer {
    padding: 15px;
    padding-top: 20px;
  }

  footer .logo__img img {
    width: 130px;
  }

  footer > div {
    gap: 20px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: center;
  }

  footer .contact__info {
    gap: 20px;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

@media screen and (max-width: 540px) {
  nav {
    padding: 15px 35px;
  }

  nav .logo__img img {
    width: 120px;
  }

  nav .navbar__contianer > li {
    display: none;
  }

  nav .navbar__contianer > li.hamMenu {
    display: flex;
  }

  .mainBody.landing .hero {
    height: 250px;
  }

  .mainBody.landing .hero .carousel__item {
    height: 250px;
  }

  .carousel .slide img {
    height: 100%;
    object-fit: cover;
  }
  
  .skew__rectangle_container {
    height: 20px;
    bottom: -1px;
  }

  .skew__rectangle_container > div {
    transform: skew(50deg) translateX(-12px);
    position: relative;
  }

  .skew__rectangle_container > div:last-of-type {
    transform: skew(-50deg) translateX(12px);
  }

  .mainBody.landing .hero .skew__rectangle_container > div::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 238, 238, 1) 100%);
  }

  .mainBody.landing .hero .skew__rectangle_container > div:last-of-type::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    background: linear-gradient(90deg,rgba(255, 238, 238, 1) 0%, rgba(255, 238, 238, 1) 100%);
  }

  .mainBody.landing .hero .carousel__item > div {
    width: 90%;
    padding-block: 15px;
    padding-inline: 10px;
    transform: translate(36px, -50%);
    padding-right: 20px;
  }

  .mainBody.landing .hero .carousel__item > div > .flag {
    transform: skew(-30deg);
  }

  .mainBody.landing .hero .carousel__item .heroText h4 {
    font-size: 24px;
  }

  .mainBody.landing .hero .carousel__item .heroText p {
    font-size: 12px;
  }

  .mainBody.landing .about h4, 
  .mainBody.landing .vision h4, 
  .mainBody.landing .mission h4, 
  .mainBody.landing .service > .service__description h4, 
  .mainBody.landing .policy > .policy__description h4, 
  .mainBody.landing .info > .info__description h4 {
    text-align: center;
    font-size: 25px;
  }

  .mainBody.landing .about p, 
  .mainBody.landing .vision p, 
  .mainBody.landing .mission p, 
  .mainBody.landing .service > .service__description p, 
  .mainBody.landing .policy > .policy__description p, 
  .mainBody.landing .info > .info__description p {
    text-align: justify;
    line-height: 1.35;
    font-size: 12px;
  }

  .mainBody.landing .about {
    padding: 15px 35px;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .mainBody.landing .about::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    right: 0;
    background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 238, 238, 1) 35%, rgba(255, 238, 238, 1) 100%);
  }


  .mainBody.landing .about > div,
  .mainBody.landing .about > div:last-of-type {
    width: 100%;
  }

  .mainBody.landing .about > div:last-of-type {
    height: 250px;
  }

  .mainBody.landing .about .barrell::before {
    display: none;
  }

  .mainBody.landing .about .barrell .barrel__circle {
    width: 200px;
    height: 200px;
  }

  .mainBody.landing .about .barrell .barrel {
    width: 350px;
    height: 400px;
    bottom: -60%;
  }

  .mainBody.landing .about .barrell .barrel__mdcircle {
    width: 50px;
    height: 50px;
    top: 60px;
  }

  .mainBody.landing .about .barrell .barrel__smcircle {
    bottom: -40%;
  }

  .mainBody.landing .vision, .mainBody.landing .mission,
  .mainBody.landing .service, .mainBody.landing .policy, .mainBody.landing .info {
    padding: 50px 35px;
  }

  .mainBody.landing .vision{
    padding-top: 100px;
  }

  .mainBody.landing .vision {
    flex-direction: column;
    padding-top: 80px;
    justify-content: center;
    align-items: center;
  }

  .mainBody.landing .vision > div {
    width: 100%!important;
    text-align: center;
  }

  .mainBody.landing .vision .binocular {
    height: 200px;
    text-align: center;
  }

  .mainBody.landing .vision .binocular > div {
    justify-content: center;
  }

  .mainBody.landing .vision .binocular .binoculars__smcircle {
    top: 60px;
    left: 20%;
    transform: translate(-50%, 0px);
    display: none;
  }

  .mainBody.landing .vision .binocular .binoculars__circle {
    left: 50%;
    transform: translateX(-50%);
  }

  .mainBody.landing .vision .binocular .binoculars {
    left: 50%;
    transform: translateX(-35%);
  }

  .mainBody.landing .mission > div {
    width: 100%;
  }

  .mainBody.landing .mission .mission__img {
    display: none;
  }

  .mainBody.landing .mission .mission__description {
    padding: 20px 0px;
    text-align: center;
  }

  .mainBody.landing .mission .skew__rectangle_container {
    left: 0;
    bottom: -1px;
  }

  .mainBody.landing .service .slider_carousel > .left-flag {
    width: 35px;
    left: -50px;
  }

  .mainBody.landing .service .slider_carousel > .right-flag {
    width: 35px;
    right: -50px;
  }

  .mainBody.landing .service .services_footer p {
    font-size: 16px;
  }
  
  .mainBody.landing .policy > .policy_item__container {
    flex-wrap: wrap;
    padding: 30px 10px;
    row-gap: 20px;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item {
    width: 50%;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item > div {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item > div svg {
    width: 100%;
    height: 100%;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item > p {
    font-size: 12px;
    font-weight: 600;
    margin-top: 10px;
  }

  .mainBody.landing .service > .service__description > p, .mainBody.landing .policy > .policy__description > p, .mainBody.landing .info > .info__description > p {
    padding-inline: 0px;
  }

  .mainBody.portfolio .banner {
    height: 150px;
  }

  .mainBody.portfolio .portfolio__container {
    flex-wrap: wrap;
    gap: 30px;
    padding: 20px;
  }

  .mainBody.portfolio .portfolio__container > .portfolio__item {
    width: 100%;
    padding: 0px;
  }

  .mainBody.contact {
    height: calc(100vh - 67px);
  }

  .mainBody.contact .contactFormContainer {
    width: 100%;
    padding: 15px;
  }

  .mainBody.contact .contactFormContainer .contactForm {
    padding-inline: 0px;
  }

  .mainBody.contact .contactFormContainer .contactHeader > h2 {
    font-size: 32px;
  }

  .mainBody.contact .contactFormContainer .contactForm .mainForm > div.w-50 {
    width: 100%;
  }

  footer > div {
    gap: 30px;
    flex-wrap: nowrap;
    flex-direction: row;
    position: relative;
  }

  footer .contact__info > div {
    max-width: initial;
  }

  footer .contact__info > div,
  footer .contact__info > div a {
    font-size: 12px;
  }

  footer .contact__info .social_icons {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media screen and (max-width: 390px) {
  .mainBody.landing .hero .carousel__item > div {
    width: 80%;
    transform: translate(36px, -80%)
  }
  .mainBody.landing .hero .carousel__item .heroText h4 {
    font-size: 18px;
  }
  .mainBody.landing .hero .carousel__item .heroText p {
    font-size: 8px;
  }
}

@media screen and (max-width: 280px) {
  nav {
    padding: 15px;
  }

  nav .logo__img img {
    width: 90px;
  }

  nav .navbar__contianer > li {
    display: none;
  }

  .mainBody.landing .hero .carousel__item .heroText h4 {
    font-size: 16px;
  }

  .mainBody.landing .hero .carousel__item .heroText p {
    font-size: 10px;
  }

  .mainBody.landing .vision .binocular .binoculars__smcircle {
    top: 45px;
    left: 8%;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item > div {
    width: 80px;
    height: 80px;
    margin-left: auto;
    margin-right: auto;
  }

  .mainBody.landing .policy > .policy_item__container > .policy_item > div > svg {
    width: 100%;
    height: 100%;
  }
}